import { ReactNode } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";

import { Text, Token } from "@ctv/momentum";

import { useHoverable } from "../../hooks/useHoverable";

type MenuProps = {
  testID?: string;
  isActive?: boolean;
  onPress?(): void;
  text: string;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  activeStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  disabledStyle?: StyleProp<ViewStyle>;
  disabledTextStyle?: StyleProp<TextStyle>;
};

type MenuPropsWithLeftIcon = MenuProps & {
  LeftIcon: ReactNode;
  LeftIconActive: ReactNode;
};

type MenuPropsWithLRightIcon = MenuProps & {
  RightIcon: ReactNode;
  RightIconActive: ReactNode;
};

type MenuPropsWithIcons = MenuProps & {
  LeftIcon: ReactNode;
  LeftIconActive: ReactNode;
  RightIcon: ReactNode;
  RightIconActive: ReactNode;
};

type Props =
  | MenuProps
  | MenuPropsWithLeftIcon
  | MenuPropsWithLRightIcon
  | MenuPropsWithIcons;

export default function MenuItem(props: Props) {
  const {
    testID,
    isActive,
    onPress,
    text,
    textStyle: textStyleProps,
    style,
    activeStyle: activeStyleProps,
    disabled,
    disabledStyle: disabledStyleProps,
    disabledTextStyle,
  } = props;

  const textStyle = textStyleProps || {
    color: isActive ? Token.color.lightPrimary : Token.color.darkSecondary,
  };

  const [isHovered, eventHandlers] = useHoverable();
  const hoveredStyle = isHovered && {
    backgroundColor: String(
      Token.opacity.washedOut(Token.color.lightSecondary)
    ),
  };
  const activeStyle =
    isActive &&
    (activeStyleProps || {
      backgroundColor: Token.color.bluePrimary,
    });

  const disabledStyle = disabled && disabledStyleProps;
  return (
    <TouchableOpacity
      testID={testID}
      activeOpacity={0.5}
      onPress={onPress}
      disabled={disabled}
    >
      <View
        {...eventHandlers}
        style={[Style.menu, hoveredStyle, activeStyle, style, disabledStyle]}
      >
        {"LeftIcon" in props && (
          <View style={Style.leftIcon}>
            {isActive ? props.LeftIconActive : props.LeftIcon}
          </View>
        )}
        <Text style={[textStyle, disabled && disabledTextStyle]}>{text}</Text>
        {"RightIcon" in props && (
          <View style={Style.rightIcon}>
            {isActive ? props.RightIconActive : props.RightIcon}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

const Style = StyleSheet.create({
  menu: {
    flexDirection: "row",
    alignItems: "center",
    padding: Token.spacing.m,
    transitionProperty: "background-color",
    transitionDuration: `${Token.timing.instant}ms`,
    marginHorizontal: Token.spacing.xs,
  },
  leftIcon: {
    marginRight: Token.spacing.xs,
  },
  rightIcon: {
    marginLeft: "auto",
  },
});
